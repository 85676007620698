@import "abstracts/_abstracts";
.InputBase {
	width: 100%;
	display: block;
	border: var(--sizeInputBorder) solid var(--colorInputBorder);
	background: var(--colorInputBackground);
	border-radius: var(--sizeInputBorderRadius);
	padding: var(--sizeInputPadding);
	font-size: var(--sizeInputFont);
	transition: var(--animationBase);
	outline: none;

	&.is-invalid {
		border-color: var(--colorErrorsDefault);
		background-color: var(--colorInputBackgroundInvalid);
	}

	&:focus {
		border-color: var(--colorInputBorderFocus);
		background-color: var(--colorInputBackgroundFocus);
	}
}